import React from "react"
import Link from "gatsby-link"
import Helmet from "react-helmet"
import styled from "styled-components"
import { graphql } from "gatsby"

import SwaggerUI from "swagger-ui"
import "swagger-ui/dist/swagger-ui.css"

import Layout from "../components/Layout/layout"
import config from "../../gatsby-config"
import SiteHeader from '../components/Layout/Header'
import TableOfContents from "../components/Layout/TableOfContents"

class OpenApi extends React.Component {

  componentDidMount() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.postBySlug;
    const post = postNode.frontmatter;
    const schemaPath = "/schemas/" + post.openApiPath;

    SwaggerUI({
      dom_id: "#apiContent",
      url: schemaPath
    })
  }

  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.postBySlug;
    const post = postNode.frontmatter;

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${post.title} | ${config.siteMetadata.siteTitle}`}</title>
        </Helmet>
        <Layout>
        <BodyGrid>
          <HeaderContainer>
              <SiteHeader location={this.props.location} />
          </HeaderContainer>
          <ToCContainer>
            <TableOfContents
              posts={this.props.data.allPostTitles.edges}
              contentsType="doc"
              chapterTitles={config.siteMetadata.toCChapters}
            />
          </ToCContainer>
          <BodyContainer>
            <div id="apiContent" />
          </BodyContainer>
        </BodyGrid>
        </Layout>
      </React.Fragment>
    );
  }
}

const BodyGrid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 75px 1fr;
  grid-template-columns: 300px 1fr;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    height: inherit;
  }
`
const BodyContainer = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  overflow: scroll;
  justify-self: center;
  width: 100%;
  padding: ${props => props.theme.sitePadding};
  @media screen and (max-width: 600px) {
    order: 2;
  }

  & > div {
    max-width: ${props => props.theme.contentWidthLaptop};
    margin: auto;
  }

  & > h1 {
    color: ${props => props.theme.accentDark};
  }
`
const HeaderContainer = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  z-index: 2;
   @media screen and (max-width: 600px) {
    order: 1;
  }
`
const ToCContainer = styled.div`
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  background: ${props => props.theme.lightGrey};
  overflow: scroll;
   @media screen and (max-width: 600px) {
    order: 3;
    overflow: inherit;
  }
`

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query ApiBySlug($slug: String!) {
    allPostTitles: allMarkdownRemark{
        edges {
          node {
            frontmatter {
              title
              section
              order
              type
              hidden
            }
            fields {
              slug
            }
          }
        }
      }
      postBySlug: markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        timeToRead
        excerpt
        frontmatter {
          title
          openApiPath
        }
        fields {
          slug
        }
      }
  }
`;

export default OpenApi;
